<template>
  <van-list class="order-list"
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <van-cell-group inset
      class="order-item"
      v-for="order in orders"
      :key="order.id"
    >
      <van-cell 
        icon="orders-o"
        :title="order.id"
        :value="order.statusText"
        clickable
        :to="getOrderDetailRouter(order.id)"
      />
      <van-cell
        :to="getOrderDetailRouter(order.id)"
      >
        <van-card
          v-for="goods in order.goodsRefs.slice(0, 1)"
          :key="goods.goodsId"
          :title="goods.goods.name"
          :num="goods.goodsNum"
          :desc="goods.goods.summary"
          :price="formatPrice(goods.goods.price, false)"
          :thumb="goods.goods.picture"
        >
        </van-card>
      </van-cell>
      <van-cell
        :to="getOrderDetailRouter(order.id)"
      >
        <van-row justify="space-between">
          <van-col span="14">{{ order.createTimeText }}</van-col>
          <van-col span="10">实付：{{ formatPrice(order.payAmount) }}</van-col>
        </van-row>
      </van-cell>
    </van-cell-group>
  </van-list>

  <FootTabbar active="order"></FootTabbar>
</template>

<script>
import { Toast, Dialog } from 'vant'
import FootTabbar from '../../components/foot-tabbar.vue'

export default {
  components: {
    FootTabbar
  },

  data() {
    return {
      finished: false,
      loading: false,
      orders: []
    }
  },

  methods: {
    onLoad() {
      setTimeout(() => {
        this.axios
        .post('/api/mall/loadOrderListByUser')
        .then(res=> {
          console.log("onLoad", res)
          if (res.data.success) {
            if (res.data.data.list.length > 0) {
              this.orders = res.data.data.list
              this.loading = false
            }
          } else {
            Toast(res.data.message)
          }
          this.finished = true
        });
      }, 1000);
    },

    formatPrice(price, isMark) {
      if (isMark == null) {
        isMark = true
      }
      return (isMark ? "￥" : '') + (price / 100).toFixed(2)
    },

    getOrderDetailRouter(id) {
      return { name: 'orderDetail', params: { id: id } }
    },

    gotoOrderDetail(id) {
      this.$router.push({ name: 'orderDetail', params: { id: id }})
    }
  },
};
</script>

<style lang="less">
.order-list {
  margin: 2% auto;
  columns: 1;

  .order-item {
    margin-bottom: 3%;
    background-color: #fff;
  }
}
</style>